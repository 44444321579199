<template>
  <v-tooltip left v-if="limit > 0" max-width="200">
    <template v-slot:activator="{ on, attrs }">
      <div class="max-acq" v-bind="attrs" v-on="on">
        <span>{{ limit }} pz tot</span>
      </div>
    </template>
    <span v-text="tooltip"> </span>
  </v-tooltip>
</template>
<style scoped lang="scss">
.max-acq {
  width: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  span {
    text-transform: uppercase;
    padding: 2px 2px;
    color: #ffffff;
    line-height: 1;
    background-color: $primary;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    text-align: center;
    border-radius: $border-radius-root;
    justify-content: center;
    align-items: center;
  }
}
</style>
<script>
import get from "lodash/get";
export default {
  name: "ProductAcqLimit",
  props: ["product"],
  data() {
    return {
      useProductClassRuleMax: false
    };
  },
  computed: {
    limit() {
      let max = get(this.product, "productClassRuleMax.max", 0);

      if (
        this.product.maxAcq &&
        this.product.productClassRuleMax &&
        this.product.productClassRuleMax.max
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.useProductClassRuleMax = !(max > this.product.maxAcq);
      } else if (!this.product.maxAcq) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.useProductClassRuleMax = true;
      } else if (
        !(
          this.product.productClassRuleMax &&
          this.product.productClassRuleMax.max
        )
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.useProductClassRuleMax = false;
      }

      let maxAcq = this.useProductClassRuleMax ? max : this.product.maxAcq;
      return maxAcq;
      // let multiPack = get(this.product, "productInfos.MULTI_PACK", 1);
      // return maxAcq / multiPack;

      // let max = get(this.product, "productClassRuleMax.max");
      // let maxA = get(this.product, "maxAcq");
      // let maxAcq = this.getMaxAcq(max, maxA);
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.useProductClassRuleMax = !(max > maxAcq);
      // let multiPack = get(
      //   this.product,
      //   "productInfos.MULTI_PACK",
      //   1
      // );
      // return maxAcq / multiPack;
    },
    tooltip() {
      if (this.useProductClassRuleMax) {
        return this.$t(
          "product.maxAcqClassTooltip",
          this.product.productClassRuleMax
        );
      } else {
        return this.$t("product.maxAcqTooltip", { count: this.limit });
      }
    }
  },
  methods: {
    getMaxAcq(max, maxA) {
      let result = undefined;
      if (max && maxA) {
        result = !(max > maxA) ? max : maxA;
      } else if (!max && maxA) {
        result = maxA;
      } else if (max && !maxA) {
        result = max;
      }
      return result;
    }
  }
};
</script>
